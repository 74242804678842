import React, { useEffect, useState, useRef } from "react";

// Components
import ScrollTop from "components/layout/ScrollTop";

// Assets
import sinImg from "assets/images/sinegy_com_1.jpg";
import sinImg2 from "assets/images/sinegy_com_12.jpg";
import sinImg3 from "assets/images/sinegy_com_14.jpg";

const SinCorpo = (props) => {
  const work = {
    images: {
      one: sinImg,
      two: sinImg2,
      three: sinImg3
    },
    title: "SINEGY Corporate",
    link: process.env.REACT_APP_SINEGY_URL,
    sin_link: process.env.REACT_APP_SINEGY_URL,
    sc_link: process.env.REACT_APP_SC_URL
  };

  const isMounted = useRef(false);
  const [scrollTopState, setScrollTopState] = useState(false);

  const checkScrollPos = async () => {
    const state = (props.scrollTop >= 1000 ? true : false);
    await setScrollTopState(state);
  }

  useEffect(() => {
    isMounted.current = true;
  }, []);

  useEffect(() => {
    if (isMounted.current) { checkScrollPos(); }
  }, [isMounted, props.scrollTop]);
  
  return (
    <div id="works" className="root-con">
      {scrollTopState && (
        <ScrollTop backToTop={props.scrollToTop} />
      )}
      
      <div className="full-page">
        <div className="full-page-con container">
          <div className="jumbotron my-auto">
            <div className="row">
              <div className="work-view-col col-lg-10 col-12">

                <div className="work-view-box">
                  <p className="work-title">{ work.title }</p>
                  <img className="work-img" src={work.images.one} alt=""/>

                  <div className="work-section">
                    <p className="work-box-title">What is { work.title }?</p>

                    <div className="work-box">
                      <p className="work-text">
                        { work.title } is the corporate website for 
                        <a 
                        className="work-text-link"
                        href={work.sin_link}
                        rel="noreferrer"
                        target="_blank"> SINEGY</a>, where people can find out more about the company & what the company does.
                      </p>
                    </div>
                  </div>

                  <div className="work-section">
                    <p className="work-box-title">
                      Who Was It Built For?
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        { work.title } is a project built for <a 
                        className="work-text-link"
                        href={work.sin_link}
                        rel="noreferrer"
                        target="_blank">SINEGY</a> while I was under the employment of the company. <a 
                        className="work-text-link"
                        href={work.sin_link}
                        rel="noreferrer"
                        target="_blank">SINEGY</a> is one of Malaysia's own Fintech startup companies from Penang that is fully regulated by <a 
                        className="work-text-link"
                        href={work.sc_link}
                        rel="noreferrer"
                        target="_blank">Securities Commission Malaysia</a> (SC).
                      </p>
                    </div>
                  </div>

                  <div className="work-section">
                    <p className="work-box-title">
                      How { work.title } works?
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        { work.title } is a place where people can find information like achievements of the company, who are the team members & career opportunities available at the company.
                      </p>
                    </div>

                    <img className="work-img" src={work.images.two} alt="" width="600px" height="auto"/>

                    <div className="work-box">
                      <p className="work-text">
                        { work.title } will also act as a platform for <a 
                        className="work-text-link"
                        href={work.sin_link}
                        rel="noreferrer"
                        target="_blank">SINEGY</a> to release important announcements about the company.
                      </p>
                    </div>

                    <img className="work-img" src={work.images.three} alt="" width="600px" height="auto"/>
                  </div>

                  <div className="work-section">
                    <p className="work-box-title">
                      What Did I Work On?
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        I was tasked to work on both Front & Back End.
                      </p>
                    </div>
                  </div>

                  <div className="work-section">
                    <p className="work-box-title">
                      Where Can I See This Project?
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        Click <a 
                        className="work-text-link"
                        href={work.link}
                        rel="noreferrer"
                        target="_blank">HERE</a> to view the { work.title } project. Please note that versions may vary due to new updates.
                      </p>
                    </div>
                  </div>

                  <div className="tech-stack work-section">
                    <p className="work-box-title">
                      Tech Stack
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        Laravel, Vuejs, Bootstrap 4.
                      </p>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SinCorpo;
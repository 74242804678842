import React, { useEffect, useState, useRef } from "react";
import selfPort from "assets/images/self/li_350.jpg";

const About = (props) => {
  const winWidth = props.windowSize.width;
  const isMounted = useRef(false);
  const [isTabMob, setIsTabMob] = useState(false);

  const checkIfTabMob = async () => {
    const state = ((winWidth < 991.5) ? true : false);
    await setIsTabMob(state);
  }

  useEffect(() => {
    isMounted.current = true;
  }, []);

  useEffect(() => {
    if (isMounted.current) { checkIfTabMob(); }
  }, [isMounted, winWidth]);

  return (
    <div id="about" className="root-con">
      <div className="full-page">
        <div className="full-page-con container">
          <div className="jumbotron my-auto">

            <div className="row">
              {/* Tab/Mob image */}
              {isTabMob && (
                <>
                <div className="about-col col-md-7 col-sm-8 col-11 mx-auto">
                  <div className="img-box">
                    <img className="about-img" src={selfPort} alt="" />
                  </div>
                </div>
                </>
              )}

              <div className="about-col col-lg-8 col-md-7 col-sm-8 col-10">
                <div className="about-box">
                  <p className="about-text">
                    Hi there, <br/>
                    my name's <span className="green-text">Erin</span>. (Pronounced "air-reen") <br/>
                  </p>
                  
                  <p className="about-text">
                    A Photographer turned Software Developer from Penang, Malaysia, alumni of the NEXT Academy. <br/>
                    Believer of constant self-improvement, always inspired to learn new things. Working on projects where I can use my skillsets to improve or solve problems gives me great motivation to become a better dev.
                  </p>

                  <p className="about-text">
                    Outside of work, I love taking pictures, exploring new restos & cafes, playing video games & watching Sci-Fi films.
                  </p>
                </div>
              </div>

              {/* Desktop image */}
              {!isTabMob && (
                <>
                <div className="about-col col-lg-4 col-12">
                  <div className="img-box">
                    <img className="about-img" src={selfPort} alt="" />
                  </div>
                </div>
                </>
              )}
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default About
export async function availablePaths() {
  const paths = [
    "/",
    "/about",
    "/contact",
    "/work",
    "/work/tomyam",
    "/work/otc",
    "/work/mktp",
    "/work/sin-media",
    "/work/sin-corpo",
    "/work/ub-detect"
  ];

  return paths;
}
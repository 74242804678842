import React, { useEffect, useState, useRef } from "react";

// Components
import ScrollTop from "components/layout/ScrollTop";

// Assets
import ubdImg from "assets/images/ubd_positive_1.jpg";
import ubdImg2 from "assets/images/ubd_positive_2.jpg";

const UpperBodyDetect = (props) => {
  const work = {
    images: {
      one: ubdImg,
      two: ubdImg2
    },
    title: "Real-time Upper Body Detector",
    link: process.env.REACT_APP_UBD_URL
  };

  const isMounted = useRef(false);
  const [scrollTopState, setScrollTopState] = useState(false);

  const checkScrollPos = async () => {
    const state = (props.scrollTop >= 1000 ? true : false);
    await setScrollTopState(state);
  }

  useEffect(() => {
    isMounted.current = true;
  }, []);

  useEffect(() => {
    if (isMounted.current) { checkScrollPos(); }
  }, [isMounted, props.scrollTop]);

  return (
    <div id="works" className="root-con">
      {scrollTopState && (
        <ScrollTop backToTop={props.scrollToTop} />
      )}
      
      <div className="full-page">
        <div className="full-page-con container">
          <div className="jumbotron my-auto">
            <div className="row">
              <div className="work-view-col col-lg-10 col-12">

                <div className="work-view-box">
                  <p className="work-title">{ work.title }</p>
                  <img className="work-img" src={work.images.one} alt=""/>

                  <div className="work-section">
                    <p className="work-box-title">What is { work.title }?</p>

                    <div className="work-box">
                      <p className="work-text">
                        { work.title } is software built to detect human upper body in real-time.
                      </p>
                    </div>
                  </div>

                  <div className="work-section">
                    <p className="work-box-title">
                      Who Was It Built For?
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        { work.title } is a personal project built out of fun & curiosity for computer vision.
                      </p>
                    </div>
                  </div>

                  <div className="work-section">
                    <p className="work-box-title">How { work.title } works?</p>

                    <div className="work-box">
                      <p className="work-text">
                        { work.title } detects human upper body from videos or live video feeds with the upperbody Haar Cascade from OpenCV.
                      </p>
                    </div>

                    <img className="work-img" src={work.images.two} alt="" width="600px" height="auto"/>

                    <div className="work-box">
                      <p className="work-text">
                        Once a positive human upper body is detected, { work.title } will hightlight the positves with a green box. { work.title } is able to detect one or more human upper bodies at one time.
                      </p>
                    </div>
                  </div>

                  <div className="work-section">
                    <p className="work-box-title">
                      Where Can I See This Project?
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        { work.title } is an open source project, available on GitHub. Click to <a 
                        className="work-text-link"
                        href={work.link}
                        rel="noreferrer"
                        target="_blank">HERE</a> view the project.
                      </p>
                    </div>
                  </div>

                  <div className="tech-stack work-section">
                    <p className="work-box-title">
                      Tech Stack
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        Python, OpenCV.
                      </p>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpperBodyDetect;
import React, { useEffect, useState, useRef } from "react";

// Components
import ScrollTop from "components/layout/ScrollTop";

// Assets
import blogImg from "assets/images/blog_1.jpg";
import blogImg2 from "assets/images/blog_9.jpg";

const Blog = (props) => {
  const work = {
    images: {
      one: blogImg,
      two: blogImg2
    },
    title: "Media Centre",
    link: process.env.REACT_APP_BLOG_URL,
    sin_link: process.env.REACT_APP_SINEGY_URL,
    sc_link: process.env.REACT_APP_SC_URL
  };

  const isMounted = useRef(false);
  const [scrollTopState, setScrollTopState] = useState(false);

  const checkScrollPos = async () => {
    const state = (props.scrollTop >= 1000 ? true : false);
    await setScrollTopState(state);
  }

  useEffect(() => {
    isMounted.current = true;
  }, []);

  useEffect(() => {
    if (isMounted.current) { checkScrollPos(); }
  }, [isMounted, props.scrollTop]);

  return (
    <div id="works" className="root-con">
      {scrollTopState && (
        <ScrollTop backToTop={props.scrollToTop} />
      )}

      <div className="full-page">
        <div className="full-page-con container">
          <div className="jumbotron my-auto">
            <div className="row">
              <div className="work-view-col col-lg-10 col-12">

                <div className="work-view-box">
                  <p className="work-title">{ work.title }</p>
                  <img className="work-img" src={work.images.one} alt=""/>

                  <div className="work-section">
                    <p className="work-box-title">What is { work.title }?</p>

                    <div className="work-box">
                      <p className="work-text">
                        { work.title } is a blog for 
                        <a 
                        className="work-text-link"
                        href={work.sin_link}
                        rel="noreferrer"
                        target="_blank"> SINEGY</a>, where the company shares the latest news & insights happening in & around the crypto world.
                      </p>
                    </div>
                  </div>

                  <div className="work-section">
                    <p className="work-box-title">
                      Who Was It Built For?
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        { work.title } is a project built for <a 
                        className="work-text-link"
                        href={work.sin_link}
                        rel="noreferrer"
                        target="_blank">SINEGY</a> while I was under the employment of the company. <a 
                        className="work-text-link"
                        href={work.sin_link}
                        rel="noreferrer"
                        target="_blank">SINEGY</a> is one of Malaysia's own Fintech startup companies from Penang that is fully regulated by <a 
                        className="work-text-link"
                        href={work.sc_link}
                        rel="noreferrer"
                        target="_blank">Securities Commission Malaysia</a> (SC).
                      </p>
                    </div>
                  </div>

                  <div className="work-section">
                    <p className="work-box-title">
                      How { work.title } works?
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        { work.title } is essentially a blog for <a 
                        className="work-text-link"
                        href={work.sin_link}
                        rel="noreferrer"
                        target="_blank">SINEGY</a> to create blog posts & share their insights with their community & followers.
                      </p>
                    </div>

                    <img className="work-img" src={work.images.two} alt="" width="600px" height="auto"/>

                    <div className="work-box">
                      <p className="work-text">
                        Besides being able to get the latest cryptocurrency prices, { work.title } operates rather similarly with other blog posting platforms.
                      </p>
                    </div>
                  </div>

                  <div className="work-section">
                    <p className="work-box-title">
                      What Did I Work On?
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        I was tasked to work on both Front & Back End, & to setup & maintain the servers.
                      </p>
                    </div>
                  </div>

                  <div className="work-section">
                    <p className="work-box-title">
                      Where Can I See This Project?
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        Click <a 
                        className="work-text-link"
                        href={work.link}
                        rel="noreferrer"
                        target="_blank">HERE</a> to view the { work.title } project. Please note that versions may vary due to new updates.
                      </p>
                    </div>
                  </div>

                  <div className="tech-stack work-section">
                    <p className="work-box-title">
                      Tech Stack
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        PostgreSQL, GraphQL, ExpressJs, NodeJs, Laravel, Vuejs, Bootstrap 4.
                      </p>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
import React, { useEffect, useState, useRef } from "react";

// Components
import ScrollTop from "components/layout/ScrollTop";

// Assets
import mktpImg from "assets/images/marketplace_7.jpg";
import mktpImg2 from "assets/images/marketplace_13.jpg";
import mktpImg3 from "assets/images/marketplace_8.jpg";
import mktpImg4 from "assets/images/marketplace_19.jpg";

const Mktp = (props) => {
  const work = {
    images: {
      one: mktpImg,
      two: mktpImg2,
      three: mktpImg3,
      four: mktpImg4
    },
    title: "Marketplace",
    link: process.env.REACT_APP_MKTP_URL,
    sin_link: process.env.REACT_APP_SINEGY_URL,
    sc_link: process.env.REACT_APP_SC_URL
  };

  const isMounted = useRef(false);
  const [scrollTopState, setScrollTopState] = useState(false);

  const checkScrollPos = async () => {
    const state = (props.scrollTop >= 1000 ? true : false);
    await setScrollTopState(state);
  }

  useEffect(() => {
    isMounted.current = true;
  }, []);

  useEffect(() => {
    if (isMounted.current) { checkScrollPos(); }
  }, [isMounted, props.scrollTop]);

  return (
    <div id="works" className="root-con">
      {scrollTopState && (
        <ScrollTop backToTop={props.scrollToTop} />
      )}

      <div className="full-page">
        <div className="full-page-con container">
          <div className="jumbotron my-auto">
            <div className="row">
              <div className="work-view-col col-lg-10 col-12">

                <div className="work-view-box">
                  <p className="work-title">{ work.title }</p>
                  <img className="work-img" src={work.images.one} alt=""/>

                  <div className="work-section">
                    <p className="work-box-title">What is { work.title }?</p>

                    <div className="work-box">
                      <p className="work-text">
                        { work.title } is an advanced Digital Assets Exchange (DAX) platform, whereby <a 
                          className="work-text-link"
                          href={work.sin_link}
                          rel="noreferrer"
                          target="_blank">SINEGY</a> is the market maker of the platform.
                      </p>
                    </div>
                  </div>

                  <div className="work-section">
                    <p className="work-box-title">
                      Who Was It Built For?
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        { work.title } is a project built for <a 
                        className="work-text-link"
                        href={work.sin_link}
                        rel="noreferrer"
                        target="_blank">SINEGY</a> while I was under the employment of the company. <a 
                        className="work-text-link"
                        href={work.sin_link}
                        rel="noreferrer"
                        target="_blank">SINEGY</a> is one of Malaysia's own Fintech startup companies from Penang that is fully regulated by <a 
                        className="work-text-link"
                          href={work.sc_link}
                          rel="noreferrer"
                        target="_blank">Securities Commission Malaysia</a> (SC).
                      </p>
                    </div>
                  </div>

                  <div className="work-section">
                    <p className="work-box-title">
                      How { work.title } works?
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        People who are interested to trade with { work.title } would have to register as a user on { work.title }. Once registered, users will have to go through a thorough verification process called Know Your Customer (KYC), to verify the legitimacy & identity of the users before they are allowed to trade with { work.title }.
                      </p>
                    </div>

                    <img className="work-img" src={work.images.two} alt="" width="600px" height="auto"/>

                    <div className="work-box">
                      <p className="work-text">
                        Once verified, users are then able to create their own crypto wallets, update their banking information, do both deposits & withdrawals & start trading on { work.title }. Users are also able to see & download their orders/trades data that took place on { work.title }.
                      </p>
                    </div>

                    <img className="work-img" src={work.images.three} alt="" width="600px" height="auto"/>

                    <div className="work-box">
                      <p className="work-text">
                        On { work.title }, users are encouraged to enable Two Factor Authentication (2FA) for extra security, to protect their accounts from a possible breach.
                      </p>
                    </div>

                    <img className="work-img" src={work.images.four} alt="" width="600px" height="auto"/>
                  </div>

                  <div className="work-section">
                    <p className="work-box-title">
                      What Did I Work On?
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        I was tasked to work on both Front & Back End.
                      </p>
                    </div>
                  </div>

                  <div className="work-section">
                    <p className="work-box-title">
                      Where Can I See This Project?
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        Click <a 
                        className="work-text-link"
                        href={work.link}
                        rel="noreferrer"
                        target="_blank">HERE</a> to view the { work.title } project. Please note that versions may vary due to new updates.
                      </p>
                    </div>
                  </div>

                  <div className="tech-stack work-section">
                    <p className="work-box-title">
                      Tech Stack
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        PostgreSQL, Laravel, Vuejs, Bootstrap 4.
                      </p>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mktp;
import { Link } from "react-router-dom";
import { Animated } from "react-animated-css";
import glitchVid from 'assets/videos/glitch_4.mp4';

const NotFound = () => {
  return (
    <div id="notFound" className="root-con">
      {/* BG video */}
      <Animated animationIn="fadeIn" animationInDelay={3.5} isVisible={true}>
        <div className="full-vid-con">
          <video className="bg-vid" autoPlay loop muted>
            <source src={glitchVid} type="video/mp4"/>
          </video>
          <div className="vid-overlay"></div>
        </div>
      </Animated>

      <div className="full-page">
        <div className="full-page-con container">
          <div className="jumbotron my-auto">
            <div className="row">
              <div className="not-found-col col-lg-10 col-12">

                <p className="error-text">
                  Error 404 - Page Not Found
                </p>

                <p className="poem-text">
                  Roses are red,
                </p>
                <p className="poem-text">
                  Violets are blue,
                </p>
                <p className="poem-text">
                  Looks like this page is dead,
                </p>
                <p className="poem-text">
                  Press button to continue.
                </p>

                <div className="btn-box">
                  <Link className="error-link" to="/">
                    Continue
                  </Link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
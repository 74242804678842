const Contact = (props) => {
  const email = process.env.REACT_APP_EMAIL;
  const ghLink = process.env.REACT_APP_GITHUB_URL;
  const mdLink = process.env.REACT_APP_MEDIUM_URL;
  const igLink = process.env.REACT_APP_IG_URL;
  const liLink = process.env.REACT_APP_LINKEDIN_URL;
  const mailTo = `mailto:${email}`;
  
  return (
    <div id="contact" className="root-con">
      <div className="full-page">
        <div className="full-page-con container">
          <div className="jumbotron my-auto">
            <div className="row">

              <div className="contact-col col-md-6 col-sm-8 col-10">
                <div className="contact-box">

                  <div className="contact-link-div">
                    <p className="contact-text">
                      Drop me an email or contact me on LinkedIn.
                    </p>
                  </div>

                  {/* Email */}
                  <div className="contact-link-div">
                    <a 
                    className="contact-link"
                    href={mailTo}>
                      {email}
                    </a>
                  </div>

                  {/* LinkedIn */}
                  <div className="contact-link-div">
                    <a 
                    className="contact-link"
                    href={liLink}
                    rel="noreferrer"
                    target="_blank">
                      LinkedIn
                    </a>
                  </div>

                  {/* Github */}
                  <div className="contact-link-div">
                    <a 
                    className="contact-link"
                    href={ghLink} 
                    rel="noreferrer"
                    target="_blank">
                      Github
                    </a>
                  </div>

                  {/* Medium */}
                  <div className="contact-link-div">
                    <a
                      className="contact-link"
                      href={mdLink}
                      rel="noreferrer"
                      target="_blank">
                      Medium
                    </a>
                  </div>

                  {/* Instagram */}
                  <div className="contact-link-div">
                    <a 
                    className="contact-link"
                    href={igLink} 
                    rel="noreferrer"
                    target="_blank">
                      Instagram
                    </a>
                  </div>

                </div> {/* End contact-box */}
              </div> {/* End contact-col */}

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact;
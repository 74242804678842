import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

// Helper
import { availablePaths } from 'utils/helper.js';

const Navbar = (props) => {
  const location = useLocation();
  const [isAboutPg, setIsAboutPg] = useState(false);
  const [isWorkPg, setIsWorkPg] = useState(false);
  const [isContactPg, setIsContactPg] = useState(false);

  const routes = [
    {
      id: 1,
      path: "/about",
      text: "About",
      active: isAboutPg
    },
    {
      id: 2,
      path: "/work",
      text: "Work",
      active: isWorkPg
    },
    {
      id: 3,
      path: "/contact",
      text: "Contact",
      active: isContactPg
    }
  ];

  const updatePathname = async () => {
    const curPath = location.pathname;
    const paths = await availablePaths();
    const toUpdate = paths.includes(curPath);

    if (toUpdate) { props.updatePath(curPath); }

    checkIfCurPage(curPath);
  }

  const checkIfCurPage = async (path) => {
    const aboutState = (path === routes[0].path ? true : false);
    const workState = (path === routes[1].path ? true : false);
    const contactState = (path === routes[2].path ? true : false);

    await setIsAboutPg(aboutState);
    await setIsWorkPg(workState);
    await setIsContactPg(contactState);
  }

  useEffect(() => {
    updatePathname();
  }, [location]);

  return (
    <nav className="navbar navbar-expand-lg fixed-top">
      <div className="container">
        <Link className="navbar-brand" to="/">Erin Lim</Link>

        <button
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#ddNavbar"
          aria-controls="ddNavbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
          className="navbar-toggler navbar-toggler-right"
        >
          <FontAwesomeIcon icon={ faBars } className="hamburger-icon"/>
        </button>
          
        <div id="ddNavbar" className="collapse navbar-collapse">
          <ul className="navbar-nav">
            {routes.map(route => (
              <li id={route.id} className="nav-item">
                <Link
                  className={`nav-link text-uppercase font-weight-bold ${route.active ? "active" : ""}`}
                  to={route.path}
                >
                  {route.text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar
import React, { useEffect, useState, useRef } from "react";

// Components
import ScrollTop from "components/layout/ScrollTop";

// Assets
import tyImg from "assets/images/ty_1.png";
import swapImg1 from "assets/images/ty_4.png";
import swapImg2 from "assets/images/ty_6.png";
import pfpMinterImg from "assets/images/ty_15.png";
import gameImg from "assets/images/ty_26.png";
import viewNftImg1 from "assets/images/ty_20.png";
import viewNftImg2 from "assets/images/ty_21.png";
import landImg1 from "assets/images/ty_22.png";
import landImg2 from "assets/images/ty_24.png";

const Tomyam = (props) => {
  const work = {
    images: {
      one: tyImg,
      swap_1: swapImg1,
      swap_2: swapImg2,
      game: gameImg,
      pfp_minter: pfpMinterImg,
      view_nft_1: viewNftImg1,
      view_nft_2: viewNftImg2,
      land_1: landImg1,
      land_2: landImg2,
    },
    title: "Tomyam NFT"
  };

  const isMounted = useRef(false);
  const [scrollTopState, setScrollTopState] = useState(false);

  const checkScrollPos = async () => {
    const state = (props.scrollTop >= 1000 ? true : false);
    await setScrollTopState(state);
  }

  useEffect(() => {
    isMounted.current = true;
  }, []);

  useEffect(() => {
    if (isMounted.current) { checkScrollPos(); }
  }, [isMounted, props.scrollTop]);

  return (
    <div id="works" className="root-con">
      {scrollTopState && (
        <ScrollTop backToTop={props.scrollToTop} />
      )}

      <div className="full-page">
        <div className="full-page-con container">
          <div className="jumbotron my-auto">
            <div className="row">
              
              {scrollTopState && (
                <>
                  show scroll top btn
                </>
              )}

              <div className="work-view-col col-lg-10 col-12">

                <div className="work-view-box">
                  <p className="work-title">{ work.title }</p>
                  <img className="work-img" src={work.images.one} alt=""/>

                  <div className="work-section">
                    <p className="work-box-title">What is { work.title }?</p>

                    <div className="work-box">
                      <p className="work-text">
                        {work.title} was a Web3 Metaverse project whereby Tomyam's users can use it to swap stablecoins with Tomyam's own utility token TomYamSwap (TYS), to which the TYS tokens can be used to mint/buy Tomyam NFTs & also utilize it in the Tomyam's metaverse, Tomyamverse.
                      </p>
                    </div>
                  </div>

                  <div className="work-section">
                    <p className="work-box-title">
                      Who Was It Built For?
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        {work.title} was built for 
                        <span className="work-text-link no-hover"> tomyam.io</span> while I was working as a Web3 Developer & System Admininstrator for the project. <br/>
                        <span className="work-text-link no-hover">tomyam.io</span> was a Web3 startup aiming to bring real-world utilities to virtual NFTs through {work.title} & Tomyamverse.
                      </p>
                    </div>
                  </div>

                  <div className="work-section">
                    <p className="work-box-title">
                      How { work.title } works?
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        Users are required to have their own Metamask wallet to login & interact with the platform. <br/>
                        To interact in the <span className="work-text-link no-hover"> Tomyamverse</span> or to mint a Tomyam NFT, users would have to swap their own stablecoins in order to get their hands on some TYS tokens, which can be done so through {work.title}'s own Swap feature.
                      </p>
                    </div>

                    <img className="work-img" src={work.images.swap_1} alt="" width="600px" height="auto"/>
                    <img className="work-img" src={work.images.swap_2} alt="" width="600px" height="auto"/>

                    <div className="work-box">
                      <p className="work-text">
                        Once users have acquired some TYS tokens, they are free to use it to mint any of Tomyam's NFTs, buy a piece of land/estate in the <span className="work-text-link no-hover"> Tomyamverse</span> or use it for interactions within the
                        <span className="work-text-link no-hover"> Tomyamverse</span>.
                      </p>
                    </div>

                    <img className="work-img" src={work.images.game} alt="" width="600px" height="auto" />

                    <div className="work-box">
                      <p className="work-text">
                        To mint their own Tomyam Profile Picture NFTs, users can use the platform's PFP NFT Minter whereby users can upload their own image & have it minted into an NFT, with Tomyam's own flare to it.
                      </p>
                    </div>

                    <img className="work-img" src={work.images.pfp_minter} alt="" width="600px" height="auto" />
                    
                    <div className="work-box">
                      <p className="work-text">
                        Each NFT cards will be algorithmically generated, to which determines what type of rarity the NFTs will be. <br />
                        Users can also view their minted collection on the platform or on OpenSea.
                      </p>
                    </div>

                    <img className="work-img" src={work.images.view_nft_1} alt="" width="600px" height="auto"/>
                    <img className="work-img" src={work.images.view_nft_2} alt="" width="600px" height="auto"/>

                    <div className="work-box">
                      <p className="work-text">
                        To buy their own land/estate in the <span className="work-text-link no-hover"> Tomyamverse</span>, users can use the platform's Land Map selector in the Marketplace to pick & choose which plot of land/estate they fancy.
                      </p>
                    </div>

                    <img className="work-img" src={work.images.land_1} alt="" width="600px" height="auto"/>
                    <img className="work-img" src={work.images.land_2} alt="" width="600px" height="auto" />

                    <div className="work-box">
                      <p className="work-text">
                        Once selected, users can proceed to buy the piece of land/estate, which can also be viewed in their OpenSea collection.
                      </p>
                    </div>
                  </div>

                  <div className="work-section">
                    <p className="work-box-title">
                      What Did I Work On?
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        I was tasked to work on Front End development, Back End development & system administration (cloud) for the project.
                      </p>
                    </div>
                  </div>

                  <div className="work-section">
                    <p className="work-box-title">
                      Where Can I See This Project?
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        As of the time of writing, the project is no longer live for viewing due to reasons unknown.
                      </p>
                    </div>
                  </div>

                  <div className="tech-stack work-section">
                    <p className="work-box-title">
                      Tech Stack
                    </p>

                    <div className="work-box">
                      <p className="work-text">
                        ReactJs, NodeJs, Bootstrap 5, Moralis, web3.js, Solidity.
                      </p>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tomyam;
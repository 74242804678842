import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import tyCoverImg from "assets/images/ty_cover.png";
import otcImg from "assets/images/otc_1.jpg";
import mktpImg from "assets/images/marketplace_7.jpg";
import blogImg from "assets/images/blog_1.jpg";
import corpImg from "assets/images/sinegy_com_1.jpg";
import ubdImg from "assets/images/ubd_positive_1c.jpg";
import tyMobImg from "assets/images/mobile/ty_cover.png";
import otcMobImg from "assets/images/mobile/otc_1.jpg";
import mktpMobImg from "assets/images/mobile/marketplace_7.jpg";
import blogMobImg from "assets/images/mobile/blog_1.jpg";
import corpMobImg from "assets/images/mobile/sinegy_com_1.jpg";
import ubdMobImg from "assets/images/mobile/ubd_positive_1.jpg";

const Work = (props) => {
  const items = [
    {
      id: 1,
      elId: `workItem${1}`,
      route: "tomyam",
      img: tyCoverImg,
      mob_img: tyMobImg,
      title: "Tomyam NFT",
      des: "A Web3 Metaverse project to swap cryptocurrencies, mint & buy NFTs."
    },
    {
      id: 2,
      elId: `workItem${2}`,
      route: "otc",
      img: otcImg,
      mob_img: otcMobImg,
      title: "OTC",
      des: "Over The Counter (OTC) cryptocurrency trading platform for SINEGY."
    },
    {
      id: 3,
      elId: `workItem${3}`,
      route: "mktp",
      img: mktpImg,
      mob_img: mktpMobImg,
      title: "Marketplace",
      des: "A Digital Asset Exchange (DAX) that handles cryptocurrency trading for SINEGY."
    },
    {
      id: 4,
      elId: `workItem${4}`,
      route: "sin-media",
      img: blogImg,
      mob_img: blogMobImg,
      title: "Media Centre",
      des: "A blog with the latest news & insights about all things cryptocurreny for SINEGY."
    },
    {
      id: 5,
      elId: `workItem${5}`,
      route: "sin-corpo",
      img: corpImg,
      mob_img: corpMobImg,
      title: "SINEGY Corporate",
      des: "Corporate website for SINEGY."
    },
    {
      id: 6,
      elId: `workItem${6}`,
      route: "ub-detect",
      img: ubdImg,
      mob_img: ubdMobImg,
      title: "Real-time Upper Body Detector",
      des: "A software that detects human upper body in real-time through live webcam feed or video source."
    }
  ];

  const winWidth = props.windowSize.width;
  const isMounted = useRef(false);
  const [isMob, setIsMob] = useState(false);

  const checkIfTabMob = async () => {
    const state = ((winWidth < 767) ? true : false);
    await setIsMob(state);
  }

  useEffect(() => {
    isMounted.current = true;
  }, []);

  useEffect(() => {
    if (isMounted.current) { checkIfTabMob(); }
  }, [isMounted, winWidth]);

  return (
    <div id="work" className="root-con">
      <div className="full-page">
        <div className="full-page-con container">
          <div className="jumbotron my-auto">
            <div className="row">

              <div className="work-col col-md-12 col-sm-8 col-9">
                
                <div id="carouselWork" className="carousel slide" data-bs-ride="carousel">
                  <div className="carousel-inner">
                    {items.map(item => (
                      <>
                        <div
                          id={item.elId}
                          className={`carousel-item ${item.id === 1 ? "active" : ""}`}
                          data-bs-interval="5000"
                        >
                        {!isMob ? (
                          <img src={item.img} className="carou-img" alt="" />  
                          ) : (
                          <div className="carou-img-wrapper" style={{ backgroundImage: `url(${item.mob_img})` }}></div>
                        )}
                        <div className="carousel-caption">
                          <h5 className="carou-title">{ item.title }</h5>
                          <p className="carou-text">{ item.des }</p>
                          <div className="carou-link-box">
                            <Link className="carou-link" to={ item.route }>
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>
                      </>
                    ))}
                  </div>
                  <button className="carousel-control-prev" type="button" data-bs-target="#carouselWork" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button className="carousel-control-next" type="button" data-bs-target="#carouselWork" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;
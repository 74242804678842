import { Animated } from "react-animated-css";
import video from 'assets/videos/upper_body_detector_fast.mp4';

const Home = () => {
  return (
    <div id="landing" className="root-con">
      {/* BG video */}
      <Animated animationIn="fadeIn" animationInDelay={3.5} isVisible={true}>
        <div className="full-vid-con">
          <video className="bg-vid" autoPlay loop muted>
            <source src={video} type="video/mp4" />
          </video>
          <div className="vid-overlay"></div>
        </div>
      </Animated>

      <div className="full-page">
        <div className="full-page-con container">
          <div className="jumbotron my-auto">
            <div className="row">
              <div className="home-col col-12">
                <div className="intro-box">
                  <p className="short-intro">
                    A Full Stack Software Developer based in Penang, Malaysia.
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home
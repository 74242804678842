// Dependencies
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Assets
import "./sass/app.scss";

// Layout Components
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";

// Components
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Work from "./components/Work";
import Tomyam from "./components/pages/Tomyam";
import Otc from "./components/pages/Otc";
import Mktp from "./components/pages/Mktp";
import Blog from "./components/pages/Blog";
import SinCorpo from "./components/pages/SinCorpo";
import UBDetect from "./components/pages/UpperBodyDetect";
import NotFound from "./components/NotFound";

function App() {
  const getWindowSize = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  }

  const isMounted = useRef(false);
  const appEle = useRef(null);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [scrollHeight, setScrollHeight] = useState(windowSize.height);
  const [scrollTop, setScrollTop] = useState(0);
  const [curPath, setCurPath] = useState("/");

  const timeout = (delay) => {
    return new Promise( res => setTimeout(res, parseInt(delay)) );
  }

  const getContentHeight = async () => {
    await timeout(200); // delayed to get accurate heights
    await setScrollHeight(appEle.current.clientHeight);
    // console.log(curPath, appEle.current.clientHeight);
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  const getScrollData = async (event) => {
    await setScrollTop(window.scrollY);
  }

  useEffect(() => {
    isMounted.current = true;

    window.addEventListener('scroll', getScrollData);

    return () => {
      window.removeEventListener('scroll', getScrollData);
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) { getContentHeight(); }
  }, [curPath, isMounted, appEle.current]);

  useEffect(() => {
    function handleResize() {
      setWindowSize(getWindowSize());
      getContentHeight();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <div ref={appEle} id="app" className="App">
      <Router>
        {/* Navbar Collapser */}
        <button
          id="navCollapser"
          className="close-navbar-toggler collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#ddNavbar"
          aria-controls="ddNavbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
          type="button"
          style={{ height: `${scrollHeight}px` }}
        >
        </button>
        <Navbar updatePath={setCurPath}/>

        {/* Content */}
        <div id="mainContent">
          <Routes>
            <Route exact path="/" element={<Home/> } />
            <Route exact path="/about" element={ <About windowSize={windowSize}/> } />
            <Route exact path="/contact" element={ <Contact/> } />
            <Route exact path="/work" element={<Work windowSize={windowSize}/>}/>
            <Route exact path="/work/tomyam" element={<Tomyam scrollTop={scrollTop} scrollToTop={scrollToTop}/>} />
            <Route exact path="/work/otc" element={<Otc scrollTop={scrollTop} scrollToTop={scrollToTop}/>} />
            <Route exact path="/work/mktp" element={<Mktp scrollTop={scrollTop} scrollToTop={scrollToTop}/>} />
            <Route exact path="/work/sin-media" element={<Blog scrollTop={scrollTop} scrollToTop={scrollToTop}/>} />
            <Route exact path="/work/sin-corpo" element={<SinCorpo scrollTop={scrollTop} scrollToTop={scrollToTop}/>} />
            <Route exact path="/work/ub-detect" element={<UBDetect scrollTop={scrollTop} scrollToTop={scrollToTop}/>} />
            
            {/* 404 */}
            <Route path="*" element={<NotFound/>} />
          </Routes>
        </div>
        {/* End Content */}

        <Footer/>
      </Router>
    </div>
  );
}

export default App;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointUp } from "@fortawesome/free-solid-svg-icons";

const ScrollTop = (props) => {
  return (
    <button
      className="scroll-top-btn btn"
      type="button"
      onClick={props.backToTop}
      title="Up up & away!"
    >
      <FontAwesomeIcon icon={ faHandPointUp } className="scroll-top-icon"/>
    </button>
  );
}

export default ScrollTop;
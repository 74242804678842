import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCodeBranch } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faLinkedinIn, faMedium } from "@fortawesome/free-brands-svg-icons";

const ghLink = process.env.REACT_APP_GITHUB_URL;
const mdLink = process.env.REACT_APP_MEDIUM_URL;
const igLink = process.env.REACT_APP_IG_URL;
const liLink = process.env.REACT_APP_LINKEDIN_URL;

const Footer = () => {
  const isMounted = useRef(false);
  const [year, setYear] = useState("");

  const getCopyrightYear = async () => {
    const curYear = await new Date().getFullYear();
    await setYear(curYear);
  }

  useEffect(() => {
    isMounted.current = true;
  }, []);

  useEffect(() => {
    if (isMounted.current) { getCopyrightYear(); }
  }, [isMounted]);

  return (
    <footer id="footer">
      <div id="footer-social" className="d-lg-none container">
        <div className="row">
          <div className="col-12">
            <ul className="footer-btm-my social-icons d-flex">
              <a className="github" href={ ghLink } rel="noreferrer" target="_blank" title="Github">
                <FontAwesomeIcon icon={ faCodeBranch } className="social-icon"/>
              </a>
              <a className="instagram" href={ igLink } rel="noreferrer" target="_blank" title="Instagram">
                <FontAwesomeIcon icon={ faInstagram } className="social-icon"/>
              </a>
              <a className="medium" href={ mdLink } rel="noreferrer" target="_blank" title="Medium">
                <FontAwesomeIcon icon={ faMedium } className="social-icon" />
              </a>
              <a className="linkedin" href={ liLink } rel="noreferrer" target="_blank" title="LinkedIn">
                <FontAwesomeIcon icon={ faLinkedinIn } className="social-icon"/>
              </a>
            </ul>
          </div>
        </div>
      </div>
      <div id="footer-bottom" className="container-fluid">
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-12">
                <p id="copyright-text" className="footer-btm-my">Erin Lim &copy; { year } All Rights Reserved.</p>
              </div>
              <div className="d-lg-block d-none col-md-4 col-12">
                <ul className="footer-btm-my social-icons d-flex">
                  <a className="github" href={ ghLink } rel="noreferrer" target="_blank" title="Github">
                    <FontAwesomeIcon icon={ faCodeBranch } className="social-icon"/>
                  </a>
                  <a className="instagram" href={ igLink } rel="noreferrer" target="_blank" title="Instagram">
                    <FontAwesomeIcon icon={ faInstagram } className="social-icon"/>
                  </a>
                  <a className="medium" href={ mdLink } rel="noreferrer" target="_blank" title="Medium">
                    <FontAwesomeIcon icon={ faMedium } className="social-icon" />
                  </a>
                  <a className="linkedin" href={ liLink } rel="noreferrer" target="_blank" title="LinkedIn">
                    <FontAwesomeIcon icon={ faLinkedinIn } className="social-icon"/>
                  </a>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer